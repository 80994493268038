var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"FlG4Sg-Fv2QeYmWj-uM4A"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,

    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    enabled: process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT !== "local",
});
